import { createContext, useEffect, useState, useRef, useCallback } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import socketIOClient, { Socket } from "socket.io-client";
import {
  selectAuthToken,
  selectIsAuthenticated,
} from "@/store/reducers/auth/auth.selector";
import { publicRoutes } from "./public.routes";
import { protectedRoutes } from "./protected.routes";
import { routePaths, SOCKET_URL } from "@/config";
import { persistor, store, useAppDispatch } from "@/store";
import { selectAvailability } from "@/store/reducers/app/app.selector";
import { getAppSettingsAsync } from "@/store/reducers/settings/settings.services";
import { setAuthToken } from "@/utils/setAuthToken";
import { socketChannels } from "@/config/socketChannels";
import {
  authSliceActions,
  errorInitialValue,
  setNewToken,
} from "@/store/reducers/auth/auth.slice";
import { axios, refreshToken } from "@/lib/axios";

type PropsFromRedux = ConnectedProps<typeof connector>;

export const AppContext = createContext<{
  socketIO: Socket | null;
  logout: (clearSocketRef?: boolean) => void;
}>({
  socketIO: null,
  logout: () => {},
});

const AppRoutes: React.FC<PropsFromRedux> = ({
  isAuthenticated,
  userToken,
  isAvailable,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const socketRef = useRef<Socket | null>(null);
  const [socketIO, setSocketIO] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(isAuthenticated);
  const [attempt, setAttempt] = useState(0);

  const logout = useCallback(
    (resetSocketRef = false) => {
      axios.post("/auth-s/api/v1/auth/logout").finally(() => {
        // localStorage.clear();
        // navigate(routePaths.auth, { replace: true });
        dispatch(authSliceActions.logout());
        // persistor.purge();

        if (socketRef.current) {
          socketRef.current.disconnect();
          console.log("Socket disconnected.");
          if (resetSocketRef) {
            socketRef.current = null;
          }
        }
      });
    },
    [dispatch]
  );

  const handleTokenExpired = useCallback(() => {
    setAttempt((prev) => prev + 1);

    if (attempt >= 3) {
      logout();
      setAttempt(0);
    } else {
      refreshToken()
        .then((res) => {
          const newToken = res.data;
          store.dispatch(setNewToken(newToken));
          initializeSocket(newToken);
          setAttempt(0);
        })
        .catch((err) => {
          logout();
        });
    }
  }, [attempt, logout]);

  const initializeSocket = useCallback(
    (token: string) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("Socket disconnected during initialization.");
      }

      const io = socketIOClient(SOCKET_URL, {
        path: "/realtime/ws/handshake",
        query: { token: `Bearer ${token}` },
        transports: ["websocket", "polling"],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000,
      });

      io.on(socketChannels.get_token_expired, (data: any) => {
        console.log("Token expired event received.");
        handleTokenExpired();
      });

      

      // io.on("connect_error", (error: any) => {
      //   console.error("Connection Error:", error);
      //   setAttempt((prev) => prev + 1);
      //   if (attempt >= 3) {
      //     console.log("Max connection attempts reached. Logging out.");
      //     logout();
      //   }
      // });

      socketRef.current = io;
      setSocketIO(io);
    },
    [handleTokenExpired]
  );

  useEffect(() => {
    if (userToken) {
      initializeSocket(userToken);
    }
  }, [userToken, initializeSocket]);

  useEffect(() => {
    setIsConnected(isAuthenticated);

    // if(userToken){
    //   if (location.pathname !== '/parameters/interface') {
    //     getAppSettings();
    //   }
    // }
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    if (socketRef.current) {
      if (isAvailable && !socketRef.current.connected) {
        socketRef.current.connect();
        console.log("Socket connecting...");
      } else if (!isAvailable && socketRef.current.connected) {
        socketRef.current.disconnect();
        console.log("Socket disconnecting...");
      }
    }
  }, [isAvailable]);

  const getAppSettings = async () => {
    const res = await dispatch(getAppSettingsAsync());
    if (getAppSettingsAsync.rejected.match(res)) {
      // Handle the error appropriately
    } else {
    }
  };

  setAuthToken(userToken);

  const routes = isConnected ? protectedRoutes : publicRoutes;
  const element = useRoutes(routes);

  return (
    <AppContext.Provider value={{ socketIO: socketRef.current, logout }}>
      {element}
    </AppContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  userToken: selectAuthToken,
  isAvailable: selectAvailability,
});

const connector = connect(mapStateToProps);

export default connector(AppRoutes);
